<template>
	<div v-if="platformSettings.upcomingSessions" class="agenda__upcoming__wrapper">
		<div class="agenda__upcoming">
			<div class="container">
				<h2 class="is-title">Upcoming Live Sessions</h2>
				<h3 v-if="currentDate" class="is-subtitle">{{ currentDate | dayFormat }}</h3>

				<!--
				<div v-for="session in sessionList" :key="session.id" class="agenda__session">
					<div class="agenda__session__start">
						<span>{{ session.start | timeFormat(session.date) }}</span>
					</div>
					<div
						class="agenda__session__content"
						:class="{ 'is-striped': session.sponsored }"
						:style="[session.sponsored && !session.colour && { backgroundColor: '#e3e3ff' }, session.colour && { backgroundColor: session.colour }]"
					>
						<div class="agenda__session__time">
							<div>
								<p>
									<strong v-if="isLive(session) && session.visitable === true" class="agenda__session__livenow">Live</strong>
									<span>{{ session.start | timeFormat(session.date) }} - {{ session.end | timeFormat(session.date) }}</span> CET-1
								</p>
								<small
									><span>{{ session.start | localTimeDateFormat(session.date) }} - {{ session.end | localTimeFormat(session.date) }}</span> Your local
									time</small
								>
							</div>
						</div>
						<h2>
							<router-link v-if="session.visitable === true" :to="{ name: 'AgendaSession', params: { url: session.url } }">{{ session.title }}</router-link>
							<span v-else>{{ session.title }}</span>
						</h2>
						<h3 v-if="session.session_type && session.session_type.name">{{ session.session_type.name }}</h3>

						<router-link
							v-if="session.external !== true && isLogged && session.live_track && session.live_track.url && isLive(session)"
							:to="{ name: 'LiveTrack', params: { url: session.live_track.url } }"
							class="agenda__status is-small is-live"
						>
							<span class="icon"><inline-svg :src="require('../assets/video-call.svg')" width="25"></inline-svg></span>
							<span>Join session</span>
						</router-link>
						<a
							v-if="session.external === true && session.user_allowed === true && isLogged && isLiveExternal(session)"
							class="agenda__status is-small is-live"
							:href="session.external_url"
							target="_blank"
						>
							<span class="icon"><inline-svg :src="require('../assets/video-call.svg')" width="25"></inline-svg></span>
							<span>Join session</span>
						</a>

						<div v-if="session.speakers && session.speakers.length > 0" class="agenda__session__speakers">
							<router-link
								v-for="speaker in session.speakers"
								:key="speaker.id"
								:to="{ name: 'AgendaSpeaker', params: { url: speaker.url } }"
								class="agenda__session__speaker"
							>
								<div class="image">
									<img :src="speaker.profile_picture | imageUrl" />
								</div>
								<div>
									<h3>{{ speaker.firstName }} {{ speaker.lastName }}</h3>
									<h4>{{ speaker.country }}</h4>
								</div>
							</router-link>
						</div>
						<div v-if="isLogged" class="agenda__favourite" :class="{ 'is-favourited': session.favourite }">
							<a @click.prevent="toggleFavourite(session)">
								<inline-svg :src="require('../assets/calendar-plus.svg')" width="26"></inline-svg>
							</a>
						</div>
					</div>
				</div>
-->

				<AgendaTimeline v-if="!loading && sessionList.length > 0" :current-date="currentDate" :sessions="sessionList" :alt-title="true" :starred="false" />
				<div v-if="!loading && sessionList.length === 0">
					<div class="agenda__status is-full is-processing">
						<span class="icon"><inline-svg :src="require('../assets/info.svg')" width="25"></inline-svg></span>
						<span>No more sessions for today, please check the full agenda.</span>
					</div>
					<div class="has-text-centered">
						<router-link :to="{ name: 'Agenda' }" class="button is-primary">View the full programme</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import $http from '../utils/http.js';
	import session from '../utils/session';
	import moment from 'moment-timezone';
	import platformSettings from '../utils/platformSettings';
	import AgendaTimeline from './AgendaTimeline';

	export default {
		name: 'AgendaUpcoming',
		components: { AgendaTimeline },
		data() {
			return {
				programme: {},
				dates: [],
				loading: true,
				sessionList: [],
				currentDate: null,
				currentList: false,
				search: '',
				debouncedSearch: '',
				session: session.state,
				title: 'Event programme',
				subtitle: '',
				background: '',
				platformSettings: platformSettings.state,
			};
		},
		computed: {
			allCount() {
				if (!this.programme && !this.currentDate) return false;
				if (!this.programme[this.currentDate]) return false;
				return this.programme[this.currentDate].length;
			},
			favCount() {
				if (!this.programme && !this.currentDate) return false;
				if (!this.programme[this.currentDate]) return false;
				return this.programme[this.currentDate].filter(e => e.favourite).length;
			},
			isLogged() {
				return this.session.loggedIn;
			},
			backgroundUrl() {
				return this.background && this.background.url ? `url('${this.$options.filters.imageUrl(this.background)}')` : null;
			},
		},
		mounted() {
			this.loading = true;
			$http.get(`/event-programme`).then(data => {
				this.setData(data);
			});
		},
		methods: {
			setData(data) {
				let sessions = data.data;
				sessions = sessions.reduce(function(r, a) {
					r[a.date] = r[a.date] || [];
					r[a.date].push(a);
					return r;
				}, Object.create(null));
				this.dates = Object.keys(sessions).sort((a, b) => (new Date(a) > new Date(b) ? 1 : new Date(a) < new Date(b) ? -1 : 0));
				let now = moment().tz('CET');
				let cgStart = moment(this.platformSettings.congressStart);
				if (now.isBefore(cgStart)) {
					now = cgStart;
				}

				if (this.dates.length > 0) {
					this.dates = this.dates.filter(e => {
						return now.isSame(moment(e, 'YYYY-MM-DD', 'CET'), 'day');
					});
				}

				this.currentDate = this.dates.length > 0 ? this.dates[0] : null;

				if (this.currentDate) {
					sessions = sessions[this.currentDate];
					sessions.sort((a, b) => (a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0));
					sessions.sort((a, b) =>
						moment(`${a.date} ${a.start}`).toDate() > moment(`${b.date} ${b.start}`).toDate()
							? 1
							: moment(`${a.date} ${a.start}`).toDate() < moment(`${b.date} ${b.start}`).toDate()
							? -1
							: 0
					);
					sessions = sessions.filter(e => this.isVisible(e));
				}

				if (sessions.length > 0) {
					this.sessionList = sessions;
					this.sessionList.map(e => {
						if (e.speakers) {
							e.speakers = e.speakers.sort((a, b) => (a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0));
						}
						return e;
					});
				}

				this.loading = false;
			},
			toggleFavourite(session) {
				session.favourite = !session.favourite;
				$http.put(`/event-programme-favourite/${session.id}`).then(
					() => {},
					() => {
						session.favourite = !session.favourite;
					}
				);
			},
			selectList(fav) {
				if (fav && !this.isLogged) return;
				this.currentList = fav;
				this.sessionList = fav ? this.programme[this.currentDate].filter(e => e.favourite) : this.programme[this.currentDate];
				this.sessionList.map(e => {
					if (e.speakers) {
						e.speakers = e.speakers.sort((a, b) => (a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0));
					}
					return e;
				});
			},
			isLive({ date, start, end }) {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${date} ${start}`, 'Europe/Lisbon');
				let dEnd = moment.tz(`${date} ${end}`, 'Europe/Lisbon');
				let now = moment().tz(target);

				return now.isBetween(dStart, dEnd);
			},
			isLiveExternal({ date, start, end }) {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${date} ${start}`, 'Europe/Lisbon').subtract(15, 'minutes');
				let dEnd = moment.tz(`${date} ${end}`, 'Europe/Lisbon');
				let now = moment().tz(target);

				return now.isBetween(dStart, dEnd);
			},
			isVisible({ date, end }) {
				let target = moment.tz.guess();
				let dEnd = moment.tz(`${date} ${end}`, 'Europe/Lisbon');
				let now = moment().tz(target);

				return now.isBefore(dEnd);
			},
		},
	};
</script>
