<template>
	<div>
		<div ref="topscroll" class="agenda-timeline__topscroll">
			<div class="agenda-timeline__topscroll-content" :style="{ width: trackLength }"></div>
		</div>
		<div ref="timeline" class="agenda-timeline">
			<div class="agenda-timeline__time" :style="{ width: trackLength }">
				<div class="agenda-timeline__live-btn-wrapper">
					<button v-if="isLiveDay" class="agenda-timeline__live-btn" @click.prevent="goToLive">Now</button>
				</div>
				<ul>
					<li v-for="hour in listTime">| {{ hour | calendarTimeFormat }}</li>
				</ul>
			</div>
			<div v-for="track in tracks" class="agenda-timeline__track" :style="{ width: trackLength }">
				<div class="agenda-timeline__track-title">
					<i>{{ track.order }}</i>
					<strong>{{ track.title }}</strong>
				</div>
				<div :key="refresh" class="agenda-timeline__session-wrapper">
					<div v-for="session in trackSessions(track.id)" :key="session.id" class="agenda-timeline__session" :style="computeSessionStyle(session)" @click="openSession(session)">
						<AgendaSessionBox :session="session" :view="'small'" :alt-title="altTitle" :starred="starred" />
					</div>
				</div>
			</div>
			<div v-if="hasExternals" class="agenda-timeline__track" :style="{ width: trackLength }">
				<div class="agenda-timeline__track-title agenda-timeline__track-title-multi" :style="{ height: `${externalMultiple * 200}px` }">
					<strong>Zoom Room</strong>
				</div>
				<div :key="`${refresh}-ext`" class="agenda-timeline__session-wrapper">
					<div
						v-for="session in externalSessions"
						:key="session.id"
						class="agenda-timeline__session"
						:style="computeSessionStyleAlt(session, externalSessions)"
						@click="openSession(session)"
					>
						<AgendaSessionBox :session="session" :view="'small'" :alt-title="altTitle" :starred="starred" />
					</div>
				</div>
			</div>

			<div v-if="isLiveDay" ref="liveLine" class="agenda-timeline__live-line" :style="liveLineStyle"></div>
		</div>
	</div>
</template>

<script>
	import AgendaSessionModal from './AgendaSessionModal';
	import AgendaSessionBox from './AgendaSessionBox';
	import moment from 'moment-timezone';

	const HOUR_PX = 162;

	export default {
		name: 'AgendaTimeline',
		components: { AgendaSessionBox },
		props: ['sessions', 'currentDate', 'altTitle', 'starred'],
		data() {
			return {
				date: new Date(),
				liveLineStyle: {},
				refreshInterval: null,
				refresh: 0,
				externalMultiple: 1,
			};
		},
		computed: {
			tracks() {
				let tracks = [];
				for (let session of this.sessions) {
					if (session.live_track) {
						if (!tracks.find(e => e.id === session.live_track.id)) {
							tracks.push({ ...session.live_track });
						}
					}
				}
				return tracks.sort((x, y) => x.order - y.order);
			},
			visibleRange() {
				let start = new Date(this.date);
				start.setHours(23);
				let end = new Date(this.date);
				end.setHours(0);

				let day = new Date(this.date).toISOString().slice(0, 10);

				for (let session of this.sessions) {
					if (session.live_track || session.external) {
						let sessionStart = moment(day + ' ' + session.start).toDate();
						if (sessionStart < start) {
							start = sessionStart;
						}
						let sessionEnd = moment(day + ' ' + session.end).toDate();
						if (sessionEnd > end) {
							end = sessionEnd;
						}
					}
				}

				start.setMinutes(0);

				return { start, end };
			},
			listTime() {
				let list = [];
				let start = new Date(this.visibleRange.start);
				let end = new Date(this.visibleRange.end);

				while (start < end) {
					let value = new Date(start);
					list.push(value.toISOString());
					start.setMinutes(start.getMinutes() + 30);
				}
				return list;
			},
			isLiveDay() {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${this.currentDate} 00:00:00`, 'CET');
				let dEnd = moment.tz(`${this.currentDate} 23:59:59`, 'CET');
				let now = moment().tz(target);

				return now.isBetween(dStart, dEnd);
			},
			trackLength() {
				return (134 + this.listTime.length * HOUR_PX) / 16 + 'rem';
			},
			externalSessions() {
				return this.sessions.filter(e => e.external === true);
			},
			hasExternals() {
				return this.externalSessions.length > 0;
			},
		},
		watch: {
			sessions() {
				this.externalMultiple = 1;
				this.computeLiveLine();
			},
		},
		mounted() {
			let topEl = this.$refs.topscroll;
			let tmEl = this.$refs.timeline;
			let isSyncingLeftScroll = false;
			let isSyncingRightScroll = false;

			tmEl.onscroll = function() {
				if (!isSyncingLeftScroll) {
					isSyncingRightScroll = true;
					topEl.scrollLeft = this.scrollLeft;
				}
				isSyncingLeftScroll = false;
			};

			topEl.onscroll = function() {
				if (!isSyncingRightScroll) {
					isSyncingLeftScroll = true;
					tmEl.scrollLeft = this.scrollLeft;
				}
				isSyncingRightScroll = false;
			};

			this.refreshLiveProgress();
			this.refreshInterval = window.setInterval(() => {
				this.refreshLiveProgress();
			}, 30 * 1000);
		},
		beforeDestroy() {
			if (this.refreshInterval) {
				window.clearInterval(this.refreshInterval);
			}
		},
		methods: {
			trackSessions(trackId) {
				return this.sessions.filter(e => e.live_track && e.live_track.id === trackId);
			},
			computeSessionStyle(session) {
				let sessionStart = moment(session.date + ' ' + session.start);
				let sh = sessionStart.hour();
				let sm = sessionStart.minute();

				let sessionEnd = moment(session.date + ' ' + session.end);
				let eh = sessionEnd.hour();
				let em = sessionEnd.minute();

				let diffh = (eh - sh) * 60 + (em - sm);

				let dayStart = this.visibleRange.start.getHours();

				let width = (diffh / 30) * HOUR_PX;
				let left = ((sh * 60 + sm - dayStart * 60) / 30) * HOUR_PX;

				return { width: width / 16 + 'rem', left: left / 16 + 'rem' };
			},
			computeSessionStyleAlt(session, sessions) {
				let multipleCheck = sessions.filter(e => e.date === session.date && e.start === session.start);
				let hasMulti = false;
				let multiCount = 0;
				if (multipleCheck.length > 1) {
					hasMulti = true;
					multiCount = multipleCheck.length;
					if (this.externalMultiple < multiCount) {
						this.externalMultiple = multiCount;
					}
				}
				let multiPos = multipleCheck.indexOf(session);

				let sessionStart = moment(session.date + ' ' + session.start);
				let sh = sessionStart.hour();
				let sm = sessionStart.minute();

				let sessionEnd = moment(session.date + ' ' + session.end);
				let eh = sessionEnd.hour();
				let em = sessionEnd.minute();

				let diffh = (eh - sh) * 60 + (em - sm);

				let dayStart = this.visibleRange.start.getHours();

				let width = (diffh / 30) * HOUR_PX;
				let left = ((sh * 60 + sm - dayStart * 60) / 30) * HOUR_PX;

				return {
					width: width / 16 + 'rem',
					left: left / 16 + 'rem',
					height: hasMulti ? `${100 / multiCount}%` : '100%',
					top: hasMulti && multiPos > 0 ? `${(100 / multiCount) * multiPos}%` : 0,
				};
			},
			computeLiveLine() {
				let now = moment().tz('Europe/Lisbon');
				let a = now.hour();
				let b = now.minute();

				if (now.toDate() < this.visibleRange.end) {
					let dayStart = this.visibleRange.start.getHours();

					let left = ((a * 60 + b - dayStart * 60) / 30) * HOUR_PX;

					this.liveLineStyle = { left: left / 16 + 'rem' };
				} else {
					this.liveLineStyle = { display: 'none' };
				}
			},
			refreshLiveProgress() {
				this.computeLiveLine();
				this.refresh = this.refresh + 1;
			},
			openSession(session) {
				if (session.visitable) {
					this.$gtm.dataLayer().push({
						event: 'gaEvent',
						eCategory: 'Programme Agenda Event',
						eAction: session.session_category ? session.session_category.title + ' - Timeline' : 'Timeline',
						eLabel: session.title,
						Exhibitor: session.session_ga ? session.session_ga : '(not set)',
						Booth: '(not set)',
					});
					this.$modal.show(
						AgendaSessionModal,
						{
							session,
							altTitle: this.altTitle,
							starred: this.starred,
						},
						{
							classes: 'agenda-session-modal',
							width: '90%',
							maxWidth: 800,
							height: 'auto',
							adaptive: true,
						}
					);
				}
			},
			goToLive() {
				let now = moment().tz('Europe/Lisbon');
				let a = now.hour();
				let b = now.minute();

				let dayStart = this.visibleRange.start.getHours();

				let left = ((a * 60 + b - dayStart * 60) / 30) * HOUR_PX;

				this.$refs.timeline.scroll({
					behavior: 'smooth',
					left,
				});
			},
		},
	};
</script>
