import moment from 'moment-timezone';

/*export default function congressTime() {
	const d = new Date();
	const utc = d.getTime() + d.getTimezoneOffset() * 60000;
	return new Date(utc + 3600000 * 1);
}*/

export default function congressTime() {
	return moment().tz("Europe/Lisbon").toDate();
}

export function congressTimeMoment() {
	return moment().tz("Europe/Lisbon");
}
