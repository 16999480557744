<template>
	<div>
		<h1>Congress Time Zone</h1>
		<div class="congress-time-modal__times">
			<div class="congress-time-modal__time is-congress">
				<inline-svg :src="require('../assets/time-congress.svg')" width="32"></inline-svg>
				<h2>Congress time</h2>
				<strong>{{ formatTime(congressTime) }}</strong>
				<span>Time in Porto, Portugal<br />{{ formatDay(congressTime) }}</span>
			</div>
			<div class="congress-time-modal__time is-local">
				<inline-svg :src="require('../assets/time-local.svg')" width="32"></inline-svg>
				<h2>Your local time</h2>
				<strong>{{ formatTime(localTime) }}</strong>
				<span>&nbsp;<br />{{ formatDay(localTime) }}</span>
			</div>
		</div>
		<Advert type="time-modal" />
	</div>
</template>

<script>
	import Advert from './Advert';
	import { congressTimeMoment } from '../utils/congressTime';
	import moment from 'moment-timezone';

	export default {
		name: 'CongressTimeModal',
		components: { Advert },
		props: {},
		data() {
			return {
				congressTime: null,
				localTime: null,
			};
		},
		mounted() {
			this.updateTime();
		},
		methods: {
			updateTime() {
				this.congressTime = congressTimeMoment();
				let localZone = moment.tz.guess();
				this.localTime = moment().tz(localZone);
			},
			formatTime(time) {
				if (time) {
					return time.format('LT');
				}
			},
			formatDay(time) {
				if (time) {
					return time.format('dddd, LL (z)');
				}
			},
			cancel() {
				this.$emit('close');
			},
			onClick() {
				this.$emit('close');
			},
		},
	};
</script>
